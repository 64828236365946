import React from "react";
import { NavLink } from "react-router-dom";

import {
    Header,
    Footer,
} from "../components/bsafu-ui";

export default function FinishKYC() {
    return (
        <>
            <Header />
            <div className="w-full mx-auto">
                <section className="relative py-4 overflow-hidden bg-gray-200 dark:bg-gray-900 md:py-20 lg:py-20 font-body">
                    <section className="items-center px-4 mx-auto mt-2 max-w-screen-2xl lg:px-28 hero md:grid-cols-6 lg:grid-cols-12">
                        <h2 className="text-2xl font-bold text-gray-900">
                            KYC Request Submitted
                        </h2>
                        <div class="bg-green-700 rounded-lg py-5 px-6 mt-4 mb-4 text-base text-white mb-3" role="alert">
                            Your verification has been submitted, and our team will check your KYC Request, we will contact you via email for KYC Result <br/><br/>
                            Thank you,<br />
                            Blocksafu Team
                        </div>

                        {/* <div className="p-6 bg-white rounded-md">
                            <h3 className="mb-4 font-bold">Upload Video KYC</h3>
                            <a className="px-6 py-3 mb-6 text-white bg-blue-600 rounded-md" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSc3T2jjF8efybvuUS1QIInBH7zOODTRkU8HhuTXi6OeLsO3Eg/viewform?usp=sf_link" rel="noreferrer">
                                Click in here to upload
                            </a>
                        </div>
                        <div className="p-4 mt-4 bg-white rounded-md">
                            <b>Note:</b> Video KYC will be upload on google form and will be saved private.
                        </div> */}
                    </section>
                </section>
            </div>
            <Footer />
        </>
    )
}