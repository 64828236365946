import React from "react";
import {BrowserRouter,Routes,Route} from "react-router-dom";
import Home from "./pages/Home";
import RegisterKYC from "./pages/RegisterKYC";
import FinalizeKYC from "./pages/FinalizeKYC";
import Store from "./Store";
import SuccessKYC from "./pages/FinishKYC";
import TokenGenerator from "./pages/TokenGenerator";

export default function Navigation(){
    return (
        <Store>
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<Home />} />
                    <Route path={"/register-kyc"} element={<RegisterKYC />} />
                    <Route path={"/finalize-kyc"} element={<FinalizeKYC />} />
                    <Route path={"/finish-kyc"} element={<SuccessKYC />} />
                    <Route path={"/token-generator"} element={<TokenGenerator />} />
                </Routes>
            </BrowserRouter>
        </Store>
    )
}