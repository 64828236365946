import React, { Fragment, useContext, useState, useEffect } from "react";
import { Tab } from "@headlessui/react";

import {Header, Footer, BtnWalletConnect, BrowseImage, WebcamPhotoSelfieKYC, UploadImage} from "../components/bsafu-ui";
import DatePicker from "react-datepicker";

import { Context } from "../Store";
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import { getEnv } from "../libs/Env";
import { ButtonLoading } from "../components/Forms";
import { useSearchParams } from "react-router-dom";


export default function Home() {
    const [startDate, setStartDate] = useState(new Date());
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [state, dispatch] = useContext(Context);
    const [formError,setFormError] = useState({});
    const [isAllowLocation,setIsAllowLocation] = useState(true);
    const [isLoading,setIsLoading] = useState(false);
    const [isUsingVPN,setIsUsingVPN] = useState(true);
    const [dataIp,setDataIp] = useState([]);
    const [tokenKey,setTokenKey] = useState(null)
    const [currentUrl,setCurrentUrl] = useState(window.location.href.split('?')[0]);
    const [isUsingCamera,setIsUsingCamera] = useState(false);
    const [params,setparams] = useSearchParams();



    const [form,setForm] = useState({
        firstname: "",
        lastname: "",
        email: "",
        nationality: "",
        dob: "",
        state: "",
        address1: "",
        address2: "",
        city: "",
        postalcode: "",
        phone: "",
        gender: "Male",
        documentType: "National ID",
        documentCover: "",
        documentData: "",
        documentSelfie: "",
        latitude: 0,
        longitude: 0,
        ip: 0,
        agent: {},
        ipInformation: {},
        logo: "",
        contract_address: "",
        position: ""
    })

    useEffect(()=>{
        getLocation()
        // getIpAddress();
        getIpAddress2();
        if(params.get('key')) validateKey()
    },[])



    useEffect(()=>{
        if(selectedIndex === 0 ) setForm(prevState => ({ ...prevState, ['documentType']: "Passport" }));
        else if(selectedIndex === 1 ) setForm(prevState => ({ ...prevState, ['documentType']: "National ID" }));
        else if(selectedIndex === 2 ) setForm(prevState => ({ ...prevState, ['documentType']: "Driver License" }));
        setForm(prevState => ({ ...prevState, ['documentCover']: "" }));
        setForm(prevState => ({ ...prevState, ['documentData']: "" }));
        setForm(prevState => ({ ...prevState, ['documentSelfie']: "" }));
    },[selectedIndex])

    const validateKey = () => {
        setTokenKey(params.get('key'))
        axios.get(getEnv().API_SAFU+"/check-security-key?token="+params.get('key')+"&filter="+currentUrl)
            .then(res=>{
                if(res.data.code != 200) {
                    alert("Invalid Page")
                    window.location.href = currentUrl
                } else {
                    setIsUsingCamera(false);
                }
            }).catch((e)=>{
            alert("Invalid Page")
            window.location.href = currentUrl
        })
    }

    const getLocation = () => {
        navigator.geolocation.getCurrentPosition(function(position) {
            setIsAllowLocation(true);
            setForm(prevState => ({ ...prevState, ['latitude']: position.coords.latitude }));
            setForm(prevState => ({ ...prevState, ['longitude']: position.coords.longitude }));
        },function(error){
            setIsAllowLocation(false);
            // alert("Please Allow this Browser for check your location.")
        });
    }

    const getIpAddress = () => {
        axios.get('https://www.cloudflare.com/cdn-cgi/trace')
            .then((res)=>{
                let data = res.data.trim().split('\n').reduce(function(obj, pair) {
                    pair = pair.split('=');
                    return obj[pair[0]] = pair[1], obj;
                }, {});
                setForm(prevState => ({ ...prevState, ['ip']: data.ip }));
                setForm(prevState => ({ ...prevState, ['agent']: data }));
                getIpInformation(data.ip)
            });
    }

    const getIpAddress2 = () => {
        axios.get('https://iptools-4.top10vpn.com/ip/?genToken=1')
            .then((res)=>{
                // console.log(res.data.ip)
                setForm(prevState => ({ ...prevState, ['ip']: res.data.ip }));
                setForm(prevState => ({ ...prevState, ['agent']: res.data.ua }));
                setDataIp(res.data)
                getIpInformation(res.data.ip)
                // axios.get('https://vpnapi.io/api/' + res.data.ip + '?key=2dc1735bda99477cb882e052d34e99a8')
                    // .then((res) => {
                        // console.log(res.data.security?.vpn)
                        //setForm(prevState => ({ ...prevState, ['latitude']: res.data.location.longitude }));
                        //setForm(prevState => ({ ...prevState, ['longitude']: res.data.location.latitude }));
                        // if(res.data.security?.vpn === false){
                            setIsUsingVPN(false)
                        // } else {
                            // setIsUsingVPN(true)
                        // }
                    // })
            });
    }

    const getIpInformation = (ip) => {
        axios.get(getEnv().API_SAFU+"/bullnium/api/tools/check-ip/"+ip)
            .then((res)=>{
                // console.log(res.data)
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                setForm(prevState => ({ ...prevState, ['ipInformation']: res.data.result }));
                // if(res.data.data.timezone !== timezone) {
                //   setIsUsingVPN(true)
                // } else {
                //   setForm(prevState => ({ ...prevState, ['nationality']: res.data.data.country }));
                //   setForm(prevState => ({ ...prevState, ['city']: res.data.data.city }));
                //   setForm(prevState => ({ ...prevState, ['state']: res.data.data.regionName }));
                //   setIsUsingVPN(false)
                // }

                if(res.data.result.timezone !== timezone) {
                    // setIsUsingVPN(true)
                } else {
                    setForm(prevState => ({ ...prevState, ['nationality']: res.data.result.country }));
                    setForm(prevState => ({ ...prevState, ['city']: res.data.result.city }));
                    setForm(prevState => ({ ...prevState, ['state']: res.data.result.regionName }));
                    // setIsUsingVPN(false)
                }

            });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    }



    const doSubmit = async()=> {
        setFormError({})
        let isError = false;
        if(form.contract_address === "") {
            setFormError(prevState => ({ ...prevState, ['contract_address']: "required" }));
            isError = true;
        }
        if(form.firstname === "") {
            setFormError(prevState => ({ ...prevState, ['firstname']: "required" }));
            isError = true;
        }
        if(form.lastname === "") {
            setFormError(prevState => ({ ...prevState, ['lastname']: "required" }));
            isError = true;
        }
        if(form.nationality === "") {
            setFormError(prevState => ({ ...prevState, ['nationality']: "required" }));
            isError = true;
        }
        if(form.state === "") {
            setFormError(prevState => ({ ...prevState, ['state']: "required" }));
            isError = true;
        }
        if(form.address1 === "") {
            setFormError(prevState => ({ ...prevState, ['address1']: "required" }));
            isError = true;
        }
        if(form.city === "") {
            setFormError(prevState => ({ ...prevState, ['city']: "required" }));
            isError = true;
        }
        if(form.postalcode === "") {
            setFormError(prevState => ({ ...prevState, ['postalcode']: "required" }));
            isError = true;
        }
        if(form.phone === "") {
            setFormError(prevState => ({ ...prevState, ['phone']: "required" }));
            isError = true;
        }
        if(form.documentCover === "") {
            setFormError(prevState => ({ ...prevState, ['documentCover']: "required" }));
            isError = true;
        }
        if(form.documentData === "") {
            setFormError(prevState => ({ ...prevState, ['documentData']: "required" }));
            isError = true;
        }
        if(form.documentSelfie === "") {
            setFormError(prevState => ({ ...prevState, ['documentSelfie']: "required" }));
            isError = true;
        }
        if(form.position === "") {
            setFormError(prevState => ({ ...prevState, ['position']: "required" }));
            isError = true;
        }
        if(isError) {
            toast.error("Please check your form, fill the required field")
        }

        if(!isError) {
            let formData = new FormData();
            formData.append('first_name',form.firstname);
            formData.append('last_name',form.lastname);
            formData.append('email',form.email);
            formData.append('nationality',form.nationality);
            formData.append('date_of_birth',"2022-01-01");
            formData.append('state',form.state);
            formData.append('address_1',form.address1);
            formData.append('address_2',form.address2);
            formData.append('city',form.city);
            formData.append('postcode',form.postalcode);
            formData.append('number_phone',form.phone);
            formData.append('gender',form.gender);
            formData.append('document_type',form.documentType);
            formData.append('document_cover',form.documentCover);
            formData.append('document_data',form.documentData);
            formData.append('document_selfie',form.documentSelfie);
            formData.append('ip',form.ip);
            formData.append('agent',JSON.stringify(form.agent));
            formData.append('ip_information',JSON.stringify(form.ipInformation));
            formData.append('latitude',form.latitude);
            formData.append('longitude',form.longitude);
            formData.append('wallet_address',state.account);
            formData.append('contract_address',form.contract_address);
            formData.append('position',form.position);
            formData.append('logo',form.logo);
            if(params.get('key')) {
                formData.append('token',params.get('key'));
                formData.append('filter',currentUrl);
            }
            setIsLoading(true)
            await axios.post(getEnv().API_SAFU+"/bullnium/api/blocksafu/kyc", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Bearer `+getEnv().API_AUTHORIZATION
                }
            }).then(res=>{
                if(res.data.code === 200) {
                    toast.success("Please check your email for verification", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(()=>{
                        window.location.href = "/finalize-kyc";
                    },3000)
                } else {
                    toast.error(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setIsLoading(false)
                }
            }).catch((e)=>{
                // toast.error(e.response.data.message, {
                //   position: "top-right",
                //   autoClose: 5000,
                //   hideProgressBar: false,
                //   closeOnClick: true,
                //   pauseOnHover: true,
                //   draggable: true,
                //   progress: undefined,
                // });
                alert(e)
            })
            setIsLoading(false)
        }
    }

    return (
        <>
            <Header />
            <div className="w-full mx-auto">
                <section className="py-4 overflow-hidden bg-gray-200 dark:bg-gray-900 md:py-20 lg:py-20 font-body">
                    <section className="px-4 mx-auto mt-2 max-w-screen-2xl lg:px-32">
                        <h2 className="text-2xl font-bold text-gray-900">
                            Personal Information
                        </h2>
                        <div className="w-full p-5 text-green-600 bg-green-100">Notes:
                            <br />
                            <span>1. Open this website from browser and connect wallet <br /> (make sure your browser already allow location, don't use vpn because you can't submit data if you using vpn)</span>
                            <br />
                            <span>2. Choose wallet apps to connect wallet</span>
                            <br />
                            <span>3. Make sure to connect with binance smart chain network</span>
                            <br />
                            <span>4. Fill the form and submit</span>
                        </div>
                        {/* <iframe src="https://asu.blocksafu.com" frameBorder="0" width="100%" height="300px" className="hidden"></iframe> */}
                        {!isAllowLocation && (
                                <div className="p-3 mt-5 bg-red-300">
                                    Please Allow location for continue.
                                    <button className="px-2 py-1 ml-1 text-white bg-blue-500 rounded-md" type="button" onClick={()=>window.location.reload()}>
                                        Reload
                                    </button>
                                </div>
                            )}
                            {isUsingVPN && (
                                <div className="p-3 mt-5 bg-red-300">
                                    Please Disable your VPN for continue.
                                    <button className="px-2 py-1 ml-1 text-white bg-blue-500 rounded-md" type="button" onClick={()=>window.location.reload()}>
                                        Reload
                                    </button>
                                </div>
                            )}
                            {isAllowLocation && !isUsingVPN && (
                                <form action="#" method="POST" onSubmit={(e)=>{e.preventDefault();doSubmit()}}>
                                    {/* <div className="p-3 mt-5 bg-green-300">KYC for wallet <b><i>{state.account}</i></b></div> */}

                                    <div className="grid grid-cols-1 mt-5 bg-white shadow-sm md:grid-cols-2 lg:grid-cols-2">
                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="contract_address"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {" "}
                                                Contract Address / Project Name{" "}
                                            </label>
                                            <div className="flex flex-col mt-1 rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    name="contract_address"
                                                    id="contract_address"
                                                    className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="Contract Address"
                                                    required
                                                    onChange={handleChange}
                                                />
                                                
                                            </div>
                                            {formError.contract_address && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.contract_address}
                                                                        </i>
                                                                    </small>
                                                                )}
                                        </div>
                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="position"
                                                className = "block text-sm font-medium text-gray-700" >
                                                {" "}
                                                Role In The Team{" "}
                                            </label>
                                            <div className="flex mt-1 rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    name="position"
                                                    id="position"
                                                    className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="Please enter your role"
                                                    required
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            {formError.position && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.position}
                                                                        </i>
                                                                    </small>
                                                                )}
                                        </div>

                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="first-name"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {" "}
                                                First Name{" "}
                                            </label>
                                            <div className="flex mt-1 rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    id="first-name"
                                                    className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="First Name"
                                                    required
                                                    name="firstname"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            {formError.firstname && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.firstname}
                                                                        </i>
                                                                    </small>
                                                                )}
                                        </div>
                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="last-name"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {" "}
                                                Last Name{" "}
                                            </label>
                                            <div className="flex mt-1 rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    id="last-name"
                                                    className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="Last Name"
                                                    required
                                                    name="lastname"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            {formError.lastname && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.lastname}
                                                                        </i>
                                                                    </small>
                                                                )}
                                        </div>


                                    </div>
                                    <div className="grid grid-cols-1 bg-white shadow-sm md:grid-cols-2 lg:grid-cols-2">
                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {" "}
                                                Email{" "}
                                            </label>
                                            <div className="flex mt-1 rounded-md shadow-sm">
                                                <input
                                                    type="email"
                                                    id="email"
                                                    className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="elon.musk@tesla.com"
                                                    required
                                                    name="email"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            {formError.email && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.email}
                                                                        </i>
                                                                    </small>
                                                                )}
                                        </div>
                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="last-name"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {" "}
                                                Country Of Nationality{" "}
                                            </label>
                                            <div className="flex mt-1 rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    id="last-name"
                                                    className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="USA"
                                                    name="nationality"
                                                    value={form.nationality}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            {formError.nationality && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.nationality}
                                                                        </i>
                                                                    </small>
                                                                )}
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 bg-white shadow-sm md:grid-cols-2 lg:grid-cols-2">
                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Date Of Birth
                                            </label>
                                            <div className="flex mt-1 rounded-md shadow-sm">
                                                <DatePicker
                                                    selected={startDate}
                                                    // onChange={(date) => setStartDate(date)}
                                                    onChange={(date)=>{
                                                        setForm(prevState => ({
                                                            ...prevState,
                                                            ["dob"]: date
                                                        }));
                                                        setStartDate(date)
                                                    }}
                                                    className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="state-province"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                State/Province{" "}
                                            </label>
                                            <div className="flex mt-1 rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    id="state-province"
                                                    className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="Eg: Alabama"
                                                    required
                                                    name="state"
                                                    onChange={handleChange}
                                                    value={form.state}
                                                />
                                            </div>
                                            {formError.state && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.state}
                                                                        </i>
                                                                    </small>
                                                                )}
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 bg-white shadow-sm md:grid-cols-2 lg:grid-cols-2">
                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="address-1"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {" "}
                                                Address Line 1
                                            </label>
                                            <div className="flex mt-1 rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    id="address-1"
                                                    className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="11163 Santa Monica Boulevard"
                                                    name="address1"
                                                    required
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            {formError.address1 && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.address1}
                                                                        </i>
                                                                    </small>
                                                                )}
                                        </div>
                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="address-2"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {" "}
                                                Address Line 2{" "}
                                            </label>
                                            <div className="flex mt-1 rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    id="address-2"
                                                    className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="Chicago, Illinois, AS - 1053 West Grand Avenue"
                                                    name="address2"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 bg-white shadow-sm md:grid-cols-2 lg:grid-cols-2">
                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="city"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {" "}
                                                City
                                            </label>
                                            <div className="flex mt-1 rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    name="city"
                                                    id="city"
                                                    className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="e.g New York"
                                                    onChange={handleChange}
                                                    value={form.city}
                                                    required
                                                />
                                            </div>
                                            {formError.city && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.city}
                                                                        </i>
                                                                    </small>
                                                                )}
                                        </div>
                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="zip-code"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {" "}
                                                Post Code/ZIP Code{" "}
                                            </label>
                                            <div className="flex mt-1 rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    id="zip-code"
                                                    className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="e.g 0000"
                                                    name="postalcode"
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            {formError.postalcode && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.postalcode}
                                                                        </i>
                                                                    </small>
                                                                )}
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 pb-4 bg-white shadow-sm md:grid-cols-2 lg:grid-cols-2">
                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="phone-number"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {" "}
                                                Phone Number
                                            </label>
                                            <div className="flex mt-1 rounded-md shadow-sm">
                                                <input
                                                    type="text"
                                                    id="phone-number"
                                                    className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    placeholder="e.g +1"
                                                    name="phone"
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            {formError.phone && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.phone}
                                                                        </i>
                                                                    </small>
                                                                )}
                                        </div>
                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="gender"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {" "}
                                                Gender{" "}
                                            </label>
                                            <div className="flex mt-1 rounded-md shadow-sm">
                                                <div className="flex">
                                                    <div className="flex items-center mb-2 mr-4">
                                                        <input
                                                            type="radio"
                                                            id="radio-example-1"
                                                            name="gender"
                                                            onChange={handleChange}
                                                            value="Male"
                                                            className="w-4 h-4 px-3 py-3 mr-2 text-gray-700 border rounded"
                                                            checked="checked"
                                                        />
                                                        <label htmlFor="radio-example-1" className="text-gray-600">
                                                            Male
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center mb-2">
                                                        <input
                                                            type="radio"
                                                            id="radio-example-2"
                                                            name="gender"
                                                            onChange={handleChange}
                                                            value="Female"
                                                            className="w-4 h-4 px-3 py-3 mr-2 text-gray-700 border rounded"
                                                        />
                                                        <label htmlFor="radio-example-2" className="text-gray-600">
                                                            Female
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-6 pt-6 pb-2">
                                            <label
                                                htmlFor="gender"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {" "}
                                                Project Logo{" "}
                                            </label>
                                            <div className="flex mt-1 rounded-md shadow-sm">
                                                <div className="flex">
                                                    <div className="flex items-center w-50 mb-2 mr-4">
                                                        <UploadImage type={"kyc"} onChange={(value)=>
                                                            setForm(prevState => ({
                                                                ...prevState,
                                                                ['logo']: value
                                                            }))
                                                        }/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-10 bg-white shadow-sm">
                                        <div className="px-6 pt-6 pb-2">
                                            <h2 className="text-2xl font-bold text-gray-900">
                                                Personal Documents
                                            </h2>
                                            <p className="text-gray-700">Upload any of the following personal document.</p>
                                        </div>

                                        <div className="px-6 pt-6 pb-2">
                                            <p className="mb-3 font-medium text-black">Select Document Type</p>
                                            <Tab.Group
                                                selectedIndex={selectedIndex}
                                                onChange={setSelectedIndex}
                                            >
                                                <Tab.List className="flex gap-4 mb-6 overflow-x-scroll">
                                                    <Tab as={Fragment}>
                                                        {({ selected }) => (
                                                            <button
                                                                className={
                                                                    selected
                                                                        ? "px-4 py-2 text-blue-500 border border-blue-500 rounded-md font-bold whitespace-nowrap"
                                                                        : "px-4 py-2 text-gray-900 border border-gray-400 rounded-md font-bold whitespace-nowrap"
                                                                }
                                                            >
                                                                Passport
                                                            </button>
                                                        )}
                                                    </Tab>
                                                    <Tab as={Fragment}>
                                                        {({ selected }) => (
                                                            <button
                                                                className={
                                                                    selected
                                                                        ? "px-4 py-2 text-blue-500 border border-blue-500 rounded-md font-bold whitespace-nowrap"
                                                                        : "px-4 py-2 text-gray-900 border border-gray-400 rounded-md font-bold whitespace-nowrap"
                                                                }
                                                            >
                                                                National ID
                                                            </button>
                                                        )}
                                                    </Tab>
                                                    <Tab as={Fragment}>
                                                        {({ selected }) => (
                                                            <button
                                                                className={
                                                                    selected
                                                                        ? "px-4 py-2 text-blue-500 border border-blue-500 rounded-md font-bold whitespace-nowrap"
                                                                        : "px-4 py-2 text-gray-900 border border-gray-400 rounded-md font-bold whitespace-nowrap"
                                                                }
                                                            >
                                                                Driver's License
                                                            </button>
                                                        )}
                                                    </Tab>
                                                </Tab.List>
                                                <Tab.Panels className="pb-5 mb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                    <Tab.Panel>
                                                        <div className="flex flex-col justify-start gap-3 mt-3 lg:flex-row md:flex-row">
                                                            <div className="">
                                                                <label htmlFor="" className="mb-4 text-gray-800">
                                                                    Passport Cover
                                                                </label>
                                                                <div className="flex items-center justify-center w-full mt-2">
                                                                    <UploadImage type={"kyc"} onChange={(value)=>
                                                                        setForm(prevState => ({
                                                                            ...prevState,
                                                                            ['documentCover']: value
                                                                        }))
                                                                    }/>
                                                                </div>
                                                                {formError.documentCover && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.documentCover}
                                                                        </i>
                                                                    </small>
                                                                )}
                                                            </div>
                                                            <div className="">
                                                                <label htmlFor="" className="mb-4 text-gray-800">
                                                                    Passport Data Page
                                                                </label>
                                                                <div className="flex items-center justify-center w-full mt-2">
                                                                    <UploadImage type={"kyc"} onChange={(value)=>
                                                                        setForm(prevState => ({
                                                                            ...prevState,
                                                                            ["documentData"]: value
                                                                        }))
                                                                    } />
                                                                </div>
                                                                {formError.documentData && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.documentData}
                                                                        </i>
                                                                    </small>
                                                                )}
                                                            </div>
                                                            <div className="">
                                                                <label htmlFor="" className="mb-4 text-gray-800">
                                                                    Selfie With Passport
                                                                </label>
                                                                <div className="flex items-center justify-center w-full mt-2">
                                                                    {isUsingCamera && (
                                                                        <WebcamPhotoSelfieKYC onChange={(value)=>{
                                                                            setForm(prevState => ({
                                                                                ...prevState,
                                                                                ["documentSelfie"]: value
                                                                            }));
                                                                        }} />
                                                                    )}
                                                                    {!isUsingCamera && (
                                                                        <UploadImage type={"kyc"} onChange={(value)=>
                                                                            setForm(prevState => ({
                                                                                ...prevState,
                                                                                ["documentSelfie"]: value
                                                                            }))
                                                                        } />
                                                                    )}
                                                                </div>
                                                                {formError.documentSelfie && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.documentSelfie}
                                                                        </i>
                                                                    </small>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Tab.Panel>
                                                    <Tab.Panel>
                                                        <div className="flex justify-start mt-3">
                                                            <div className="">
                                                                <label htmlFor="" className="mb-4 text-gray-800">
                                                                    National ID Front
                                                                </label>
                                                                <div className="flex items-center justify-center w-full mt-2">
                                                                    <UploadImage type={"kyc"} onChange={(value)=>
                                                                        setForm(prevState => ({
                                                                            ...prevState,
                                                                            ["documentCover"]: value
                                                                        }))
                                                                    } />
                                                                </div>
                                                                {formError.documentCover && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.documentCover}
                                                                        </i>
                                                                    </small>
                                                                )}
                                                            </div>
                                                            <div className="">
                                                                <label htmlFor="" className="mb-4 text-gray-800">
                                                                    National ID Back
                                                                </label>
                                                                <div className="flex items-center justify-center w-full mt-2">
                                                                    <UploadImage type={"kyc"} onChange={(value)=>
                                                                        setForm(prevState => ({
                                                                            ...prevState,
                                                                            ["documentData"]: value
                                                                        }))
                                                                    } />
                                                                </div>
                                                                {formError.documentData && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.documentData}
                                                                        </i>
                                                                    </small>
                                                                )}
                                                            </div>
                                                            <div className="">
                                                                <label htmlFor="" className="mb-4 text-gray-800">
                                                                    Selfie With National ID
                                                                </label>
                                                                <div className="flex items-center justify-center w-full mt-2">
                                                                    {isUsingCamera && (
                                                                        <WebcamPhotoSelfieKYC onChange={(value)=>{
                                                                            setForm(prevState => ({
                                                                                ...prevState,
                                                                                ["documentSelfie"]: value
                                                                            }));
                                                                        }} />
                                                                    )}
                                                                    {!isUsingCamera && (
                                                                        <UploadImage type={"kyc"} onChange={(value)=>
                                                                            setForm(prevState => ({
                                                                                ...prevState,
                                                                                ["documentSelfie"]: value
                                                                            }))
                                                                        } />
                                                                    )}
                                                                </div>
                                                                {formError.documentSelfie && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.documentSelfie}
                                                                        </i>
                                                                    </small>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Tab.Panel>
                                                    <Tab.Panel>
                                                        <div className="flex justify-start mt-3">
                                                            <div className="">
                                                                <label htmlFor="" className="mb-4 text-gray-800">
                                                                    Licence Front
                                                                </label>
                                                                <div className="flex items-center justify-center w-full mt-2">
                                                                    <UploadImage type={"kyc"} onChange={(value)=>
                                                                        setForm(prevState => ({
                                                                            ...prevState,
                                                                            ["documentCover"]: value
                                                                        }))
                                                                    } />
                                                                </div>
                                                                {formError.documentCover && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.documentCover}
                                                                        </i>
                                                                    </small>
                                                                )}
                                                            </div>
                                                            <div className="">
                                                                <label htmlFor="" className="mb-4 text-gray-800">
                                                                    Licence Data
                                                                </label>
                                                                <div className="flex items-center justify-center w-full mt-2">
                                                                    <UploadImage type={"kyc"} onChange={(value)=>
                                                                        setForm(prevState => ({
                                                                            ...prevState,
                                                                            ["documentData"]: value
                                                                        }))
                                                                    } />
                                                                </div>
                                                                {formError.documentData && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.documentData}
                                                                        </i>
                                                                    </small>
                                                                )}
                                                            </div>
                                                            <div className="">
                                                                <label htmlFor="" className="mb-4 text-gray-800">
                                                                    Selfie With License
                                                                </label>
                                                                <div className="flex items-center justify-center w-full mt-2">
                                                                    {isUsingCamera && (
                                                                        <WebcamPhotoSelfieKYC onChange={(value)=>{
                                                                            setForm(prevState => ({
                                                                                ...prevState,
                                                                                ["documentSelfie"]: value
                                                                            }));
                                                                        }} />
                                                                    )}
                                                                    {!isUsingCamera && (
                                                                        <UploadImage type={"kyc"} onChange={(value)=>
                                                                            setForm(prevState => ({
                                                                                ...prevState,
                                                                                ["documentSelfie"]: value
                                                                            }))
                                                                        } />
                                                                    )}
                                                                </div>
                                                                {formError.documentSelfie && (
                                                                    <small>
                                                                        <i className="text-red-900">
                                                                            {formError.documentSelfie}
                                                                        </i>
                                                                    </small>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Tab.Panel>
                                                </Tab.Panels>
                                            </Tab.Group>
                                            <div className="p-5 bg-gray-200 border border-gray-400 rounded-md">
                                                <div className="flex flex-col justify-start gap-6 lg:flex-row md:flex-row">
                                                    <div>
                                                        <div>File accepted: JPEG/JPG/PNG (Max size: 2000 KB)</div>
                                                        <div>Document must be valid period</div>
                                                        <div>Note has today’s date</div>
                                                    </div>
                                                    <div>
                                                        <div>Document should be good condition</div>
                                                        <div>Face must be clear visible</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-8">
                                                <div className="flex mb-8 items-star">
                                                    <div className="flex items-center h-5">
                                                        <input id="comments" required name="comments" type="checkbox" className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
                                                    </div>
                                                    <div className="ml-3 text-sm">
                                                        <label htmlFor="comments" className="font-medium text-gray-700">I ACCEPT THE <a href="#" onClick={(e)=>{e.preventDefault()}} className="text-blue-500">TERMS & CONDITIONS</a> AND <a href="#" onClick={(e)=>{e.preventDefault()}} className="text-blue-500">PRIVACY POLICY</a></label>
                                                    </div>
                                                </div>
                                                {/* <button type="submit" className="px-6 py-3 text-white bg-blue-500 rounded-md">
                          Register KYC
                        </button> */}
                                                <ButtonLoading title={"Submit KYC"} isLoading={isLoading} onClick={()=>doSubmit()} />

                                                {/* <p className="mt-12"><span className="font-bold text-red-700">Dev Notes</span>: after registration user will be redirect to /finalize-kyc , and on finalize-kyc page, user must be upload video kyc with google form links (automatically saved to google drive private)</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                    </section>

                </section>
            </div>
            <Footer />
        </>
    );
}
