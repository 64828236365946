import { Formik } from "formik";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Web3 from "web3";

import {
    Header,
    Footer,
    BtnWalletConnect,
} from "../components/bsafu-ui";
import { ButtonLoading } from "../components/Forms";

export default function TokenGenerator() {
  const [isLoading, setIsLoading] = useState(false)
    const onValidate = (values) => {
      const errors = {}
      if(!values.tokenName) errors.tokenName = "Required"
      else if(values.tokenName.length < 3) errors.tokenName = "Minimum 3 chars"
      else if(values.tokenName.length > 20) errors.tokenName = "Maximum 20 chars"
      
      if(!values.tokenSymbol) errors.tokenSymbol = "Required"
      else if(values.tokenSymbol.length < 2) errors.tokenSymbol = "Minimum 3 chars"
      else if(values.tokenSymbol.length > 20) errors.tokenSymbol = "Maximum 20 chars"

      if(!values.tokenSupply) errors.tokenSupply = "Required"
      else if(isNaN(values.tokenSupply)) errors.tokenSupply = "Should be number"

      if(!values.rewardToken) errors.rewardToken = "Required"
      else if(!Web3.utils.isAddress(values.rewardToken)) errors.rewardToken = "Wrong address format"

      if(!values.walletMarketing) errors.walletMarketing = "Required"
      else if(!Web3.utils.isAddress(values.walletMarketing)) errors.walletMarketing = "Wrong address format"

      if(!values.walletTreasury) errors.walletTreasury = "Required"
      else if(!Web3.utils.isAddress(values.walletTreasury)) errors.walletTreasury = "Wrong address format"

      try {
        if((parseFloat(values.percentMarketing)+parseFloat(values.percentReward)+parseFloat(values.percentTreasury)) > 25) {
          errors.percentReward = "Total Tokenomic Max is 25%"
          errors.percentTreasury = "Total Tokenomic Max is 25%"
          errors.percentMarketing = "Total Tokenomic Max is 25%"
        }
      } catch(e){}

      if(!values.percentReward) errors.percentReward = "Required"
      else if(isNaN(values.percentReward)) errors.percentReward = "Should be number"
      else if(values.percentReward < 0) errors.percentReward = "Minimum 0%";
      else if(values.percentReward > 25) errors.percentReward = "Maximum 25%";

      if(!values.percentMarketing) errors.percentMarketing = "Required"
      else if(isNaN(values.percentMarketing)) errors.percentMarketing = "Should be number"
      else if(values.percentMarketing < 0) errors.percentMarketing = "Minimum 0%";
      else if(values.percentMarketing > 25) errors.percentMarketing = "Maximum 25%";

      if(!values.percentTreasury) errors.percentTreasury = "Required"
      else if(isNaN(values.percentTreasury)) errors.percentTreasury = "Should be number"
      else if(values.percentTreasury < 0) errors.percentTreasury = "Minimum 0%";
      else if(values.percentTreasury > 25) errors.percentTreasury = "Maximum 25%";
      
      
      return errors;
    }

    const onSubmit = (values) => {
      console.log("submit",values)
    }

    return (
        <>
            <Header />
            <div className="w-full mx-auto">
                <section className="relative py-4 overflow-hidden bg-gray-200 dark:bg-gray-900 md:py-20 lg:py-20 font-body">
                    <section className="items-center mx-auto max-w-screen-2xl lg:px-28 hero md:grid-cols-6 lg:grid-cols-12">
                        <h2 className="text-2xl font-bold text-gray-900">
                            Token Generator
                        </h2>
                        <div className="bg-white rounded-lg py-5 px-6 mt-4 mb-4 mb-3" role="alert">
                            <Formik
                              initialValues={{
                                tokenName: '',
                                tokenSymbol: '',
                                tokenDecimal: 18,
                                tokenSupply: 0
                              }}
                              onSubmit={onSubmit}
                              validate={onValidate}
                            >
                              {({values,errors,handleChange,handleBlur,handleSubmit,isSubmitting}) => (
                                <form noValidate>
                                  <div className="mb-4">
                                    <label>Token Type*</label>
                                    <select
                                      type="text"
                                      id="token-type"
                                      className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      required
                                      name="tokenType"
                                      onChange={handleChange}
                                    >
                                      <option value={"SimpleToken"}>Simple Token ERC20 (Without Tokenomic)</option>
                                    </select>
                                    {errors.tokenType && <span className="text-red-700 text-sm">{errors.tokenName}</span>}
                                  </div>
                                  <div className="mb-4">
                                    <label>Token Name*</label>
                                    <input
                                      type="text"
                                      id="first-name"
                                      className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      placeholder="Ex: Blocksafu Token"
                                      required
                                      name="tokenName"
                                      onChange={handleChange}
                                    />
                                    {errors.tokenName && <span className="text-red-700 text-sm">{errors.tokenName}</span>}
                                  </div>
                                  <div className="mb-4">
                                    <label>Token Symbol*</label>
                                    <input
                                      type="text"
                                      id="token-symbol"
                                      className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      placeholder="Ex: BSAFU"
                                      required
                                      name="tokenSymbol"
                                      onChange={handleChange}
                                    />
                                    {errors.tokenSymbol && <span className="text-red-700 text-sm">{errors.tokenSymbol}</span>}
                                  </div>
                                  <div className="mb-4">
                                    <label>Token Supply*</label>
                                    <input
                                      type="text"
                                      id="token-supply"
                                      className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      placeholder="Ex: 1000000000"
                                      required
                                      name="tokenSupply"
                                      onChange={handleChange}
                                    />
                                    {errors.tokenSupply && <span className="text-red-700 text-sm">{errors.tokenSupply}</span>}
                                  </div>
                                  {/* <div className="mb-4">
                                    <label>Reward Token*</label>
                                    <input
                                      type="text"
                                      id="reward-token"
                                      className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      placeholder="Ex: 0x..."
                                      required
                                      name="rewardToken"
                                      onChange={handleChange}
                                    />
                                    {errors.rewardToken && <span className="text-red-700 text-sm">{errors.rewardToken}</span>}
                                  </div>
                                  <div className="mb-4">
                                    <label>Marketing Wallet Address*</label>
                                    <input
                                      type="text"
                                      id="marketing-wallet-address"
                                      className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      placeholder="Ex: 0x"
                                      required
                                      name="walletMarketing"
                                      onChange={handleChange}
                                    />
                                    {errors.walletMarketing && <span className="text-red-700 text-sm">{errors.walletMarketing}</span>}
                                  </div>
                                  <div className="mb-4">
                                    <label>Treasury Wallet Address*</label>
                                    <input
                                      type="text"
                                      id="treasury-wallet-address"
                                      className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      placeholder="Ex: 0x"
                                      required
                                      name="walletTreasury"
                                      onChange={handleChange}
                                    />
                                    {errors.walletTreasury && <span className="text-red-700 text-sm">{errors.walletTreasury}</span>}
                                  </div>
                                  <div className="mb-4">
                                    <label>Percent Reward(%)*</label>
                                    <input
                                      type="text"
                                      id="percent-reward"
                                      className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      placeholder="0-100"
                                      required
                                      name="percentReward"
                                      onChange={handleChange}
                                    />
                                    {errors.percentReward && <span className="text-red-700 text-sm">{errors.percentReward}</span>}
                                  </div>
                                  <div className="mb-4">
                                    <label>Percent Marketing(%)*</label>
                                    <input
                                      type="text"
                                      id="percent-marketing"
                                      className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      placeholder="0-100"
                                      required
                                      name="percentMarketing"
                                      onChange={handleChange}
                                    />
                                    {errors.percentMarketing && <span className="text-red-700 text-sm">{errors.percentMarketing}</span>}
                                  </div>
                                  <div className="mb-4">
                                    <label>Percent Treasury(%)*</label>
                                    <input
                                      type="text"
                                      id="percent-treasury"
                                      className="flex-1 block w-full px-3 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      placeholder="0-100"
                                      required
                                      name="percentTreasury"
                                      onChange={handleChange}
                                    />
                                    {errors.percentTreasury && <span className="text-red-700 text-sm">{errors.percentTreasury}</span>}
                                  </div> */}
                                  <div className="mb-4">
                                    <BtnWalletConnect>
                                      <ButtonLoading title={"Create Token"} isLoading={isLoading} type={"button"} onSubmit={()=>handleSubmit()} />
                                    </BtnWalletConnect>
                                  </div>
                                  
                                </form>
                              )}
                            </Formik>
                        </div>                        
                    </section>
                </section>
            </div>
            <Footer />
        </>
    )
}