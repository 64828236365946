import React from "react";
import { NavLink } from "react-router-dom";

import {
    Header,
    Footer,
} from "../components/bsafu-ui";

export default function Home() {
    return (
        <>
            <Header />
            <div className="w-full mx-auto">
                <section className="relative py-4 overflow-hidden bg-gray-200 dark:bg-gray-900 md:py-20 lg:py-20 font-body">
                    <section className="grid items-center grid-cols-1 gap-12 px-4 mx-auto mt-2 max-w-screen-2xl lg:px-28 hero md:grid-cols-6 lg:grid-cols-12">
                        <div className="col-span-1 md:col-span-6">
                            <div className="text-3xl font-bold leading-snug dark:text-white lg:leading-tight lg:text-5xl headline font-display md:text-7xl text-dark-1 md:leading-tight">
                                Let's do <span className="text-blue-500">KYC</span> For your crypto Project
                            </div>
                            <div className="grid grid-cols-1 mt-4 md:mt-16 lg:mt-20 md:grid-cols-12">
                                <div className="inline-flex items-center col-span-1 mb-3 md:col-span-6">
                                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.1328 1.30005L4.88281 9.55005L1.13281 5.80005" stroke="#00B67A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    <p className="ml-4 font-normal text-dark-2 dark:text-white">Safety <br /> KYC Platform</p>
                                </div>
                                <div className="inline-flex items-center col-span-1 mb-3 md:col-span-6">
                                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.1328 1.30005L4.88281 9.55005L1.13281 5.80005" stroke="#00B67A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    <p className="ml-4 font-normal text-dark-2 dark:text-white">Get Certificate KYC <br/> From BlockSAFU</p>
                                </div>
                                <div className="inline-flex items-center col-span-1 mb-3 md:col-span-6">
                                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.1328 1.30005L4.88281 9.55005L1.13281 5.80005" stroke="#00B67A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    <p className="ml-4 font-normal text-dark-2 dark:text-white">Increase Your <br/> Customer's trust</p>
                                </div>
                            </div>
                            <div className="flex flex-row gap-4 mt-16">
                                <NavLink to="/register-kyc" className="px-8 py-4 text-white bg-gray-900 rounded-lg">
                                    Register KYC
                                </NavLink>
                                <NavLink to="/create-kyc" className="px-12 py-4 text-gray-900 bg-white border-2 border-gray-900 rounded-lg">
                                    List KYC
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-span-1 md:col-span-6">
                            <div className="flex justify-center w-full">
                                <img src="./images/stake-illustration.svg" className="w-9/12" alt="" />
                            </div>
                        </div>
                    </section>
                </section>
            </div>
            <Footer />
        </>
    )
}