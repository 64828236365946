import React, { Fragment, useContext, useEffect, useState } from "react";
// import CurrencyInput from "react-currency-input-field";
import { Context } from "../Store";
import { getAppByChainId } from "../libs/Env";
import { amountFormat, getAmountDivider } from "../libs/WebooLib";
import { CircleLoading } from "./bsafu-ui";
import { BigNumber } from "bignumber.js";
import { Dialog, Transition } from "@headlessui/react";
import { useGetERC20Detail, useGetListToken } from "../hooks/web3hook";
import axios from "axios";
import { FaTimes } from "react-icons/fa";

export const InputNumberWithBalance = ({
  title,
  onChange = (val) => {},
  value,
  address,
  prefix
}) => {
  const [state, dispatch] = useContext(Context);
  const [isLoadingBalance, setIsLoadingBalance] = useState(true);
  const [userBalance, setUserBalance] = useState(0);
  const [originalBalance, setOriginalBalance] = useState(0);
  const [symbol, setSymbol] = useState("");
  const [storeHash, setStoreHash] = useState(null);
  useEffect(async () => {
    if (state.web3 && address && state.storeHash !== storeHash) {
        if(address) {
            const token = await new state.web3.eth.Contract(
                getAppByChainId(state.chainId).ERC20_ABI,
                address
            );
            const decimal = BigNumber(10).exponentiatedBy(
                await token.methods.decimals().call()
            );
            let balance = 0;
            if ((address).toLowerCase() === (getAppByChainId(state.chainId).WETH_ADDRESS).toString().toLowerCase()) {
                balance = new BigNumber(
                    await state.web3.eth.getBalance(state.web3.utils.toHex(state.account))
                )
            } else {
                balance = new BigNumber(
                    await token.methods.balanceOf(state.account).call()
                );
            }
            if (prefix) {
                setSymbol(prefix);
            } else {
                let s = await token.methods.symbol().call();
                setSymbol(s)
            }
            setUserBalance(amountFormat(balance.div(decimal)));
            if (balance.isGreaterThan(0)) {
                setOriginalBalance(balance.div(decimal).toString());
            } else setOriginalBalance(0);
            setIsLoadingBalance(false);
            setStoreHash(state.storeHash);
        }
    }
  }, [state,address]);

  const setMax = () => {
    // alert(originalBalance)
    onChange(originalBalance);
  };

  return (
    <div className="w-full mb-4">
      <div className={"flex flex-row justify-between"}>
        {title ? (
          <label
            htmlFor="username-success"
            className="block mb-1 text-sm font-medium text-black dark:text-white"
          >
            {title}
          </label>
        ) : null}
        <div className={"text-sm font-bold flex flex-row text-black dark:text-white"}>
          {isLoadingBalance ? (
            <CircleLoading size={3} />
          ) : (
            `${userBalance} ${symbol}`
          )}
        </div>
      </div>
      <div className="relative flex flex-wrap items-stretch w-full mb-4">
        <input
          type="text"
          className={
            "border-l border-t border-b border-red-600 text-black dark:text-white placeholder-red-600 text-sm focus:ring-red-900 focus:border-red-600 block w-full p-2.5 dark:bg-gray-800 dark:border-red-900 flex-shrink flex-grow leading-normal flex-1 h-10 border-grey-light px-3 relative rounded-l-lg"
          }
          onChange={(e) => {
            onChange(e);
          }}
          value={value}
          onKeyPress={(event) => {
            if (!/^[0-9]*[.]{0,1}[0-9]*$/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
        {/*<CurrencyInput*/}
        {/*    className={"border border-red-600 placeholder-red-600 text-sm focus:ring-red-900 focus:border-red-600 block w-full p-2.5 dark:bg-green-100 dark:border-red-900 flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light px-3 relative "+(subFix?"rounded-l-lg":"rounded-lg")}*/}
        {/*    name="input-name"*/}
        {/*    value={value}*/}
        {/*    decimalsLimit={18}*/}
        {/*    decimalSeparator="."*/}
        {/*    groupSeparator=""*/}
        {/*    onValueChange={(e)=>onChange(e)}*/}
        {/*    mi*/}
        {/*/>*/}
        <div className="flex -mr-px">
          <button
            className="flex items-center px-3 text-sm leading-normal text-white whitespace-no-wrap bg-blue-500 border-t border-b border-r border-red-600 rounded-r-lg hover:bg-gray-900"
            onClick={() => {
              setMax();
            }}
            type={"button"}
          >
            MAX
          </button>
        </div>
      </div>
    </div>
  );
};

export const InputNumberWithBalanceFarmingLP = ({
  title,
  onChange = (val) => {},
  value,
  addressFarming,
}) => {
  const [state, dispatch] = useContext(Context);
  const [isLoadingBalance, setIsLoadingBalance] = useState(true);
  const [userBalance, setUserBalance] = useState(0);
  const [symbol, setSymbol] = useState("");
  const [storeHash, setStoreHash] = useState(null);

  useEffect(async () => {
    if (state.web3 && state.storeHash !== storeHash) {
      const token = await new state.web3.eth.Contract(
        getAppByChainId(state.chainId).FARMING_ABI,
        state.web3.utils.toHex(addressFarming)
      );
      const balance = await token.methods.amountLPToken(state.account).call();
      setSymbol("");
      setUserBalance(getAmountDivider(balance, 18));
      setIsLoadingBalance(false);
      setStoreHash(state.storeHash);
    }
  }, [state]);

  return (
    <div className="w-full mb-4">
      <div className={"flex flex-row justify-between"}>
        {title ? (
          <label
            htmlFor="username-success"
            className="block mb-1 text-sm font-medium dark:text-green-500"
          >
            {title}
          </label>
        ) : null}
        <div className={"text-sm font-bold flex flex-row"}>
          {isLoadingBalance ? (
            <CircleLoading size={3} />
          ) : (
            `${userBalance} ${symbol}`
          )}
        </div>
      </div>
      <div className="relative flex flex-wrap items-stretch w-full mb-4">
        <input
          type="text"
          className={
            "border border-red-600 placeholder-red-600 text-sm focus:ring-red-900 focus:border-red-600 block w-full p-2.5 dark:bg-green-100 dark:border-red-900 flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light px-3 relative rounded-l-lg"
          }
          onChange={onChange}
          value={value}
          onKeyPress={(event) => {
            if (!/^[0-9]*[.]{0,1}[0-9]*$/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
        <div className="flex -mr-px">
          <span className="flex items-center px-3 text-sm leading-normal whitespace-no-wrap bg-gray-300 border border-red-600 rounded-r-lg bg-grey-lighter text-grey-dark">
            {symbol}
          </span>
        </div>
      </div>
    </div>
  );
};

export const ButtonLoading = ({
  title,
  onClick = () => {},
  isLoading = false,
  isDisabled = false,
  className=null,
  icon= null,
  type="button"
}) => {
  return (
    <button
      type={type}
      className={
        "flex items-center justify-center w-full gap-2 py-2 px-2 text-white rounded-md hover:bg-blue-600 " +
        (isLoading || isDisabled ? "bg-blue-200" : "bg-blue-500 hover:bg-gray-800" )+" "+className
      }
      onClick={() => onClick()}
      disabled={isLoading || isDisabled}
    >
      <div
        className={
          "flex flex-row align-middle justify-center gap-x-2 text-center items-center"
        }
      >
        {isLoading ? (
          <div
            style={{
              borderTopColor: "transparent",
            }}
            className="w-6 h-6 border-4 border-white border-solid rounded-full float animate-spin"
          />
        ) : (
          <div className="flex flex-row items-center justify-center">
            {icon}
            <div className={icon? 'ml-2' : null}>{title}</div>
          </div>
        )}
        
      </div>
    </button>
  );
};

export const ButtonLoadingGray = ({
  title,
  onClick = () => {},
  isLoading = false,
  isDisabled = false
}) => {
  return (
    <button
      type={"button"}
      className={
        "w-full py-2 my-3 text-gray-700 bg-gray-300 border border-gray-400 rounded-lg dark:text-gray-100 hover:bg-gray-400 dark:bg-gray-800 dark:border-gray-800 " +
        (isLoading || isDisabled ? "bg-gray-200" : "bg-gray-200 hover:bg-gray-800")
      }
      onClick={() => onClick()}
      disabled={isLoading || isDisabled}
    >
      <div
        className={
          "flex flex-row align-middle justify-center gap-x-2 text-center items-center"
        }
      >
        {isLoading ? (
          <div
            style={{
              borderTopColor: "transparent",
            }}
            className="w-6 h-6 border-4 border-white border-solid rounded-full float animate-spin"
          />
        ) : null}
        <div>{title}</div>
      </div>
    </button>
  );
};

const RowToken = ({address,name,symbol,onSelect=(e)=>{}}) => {

  const [{tokenName,tokenSymbol,tokenDecimal,tokenSupply,tokenBalance,isLoadingTokenInfo},fetchTokenInfo] = useGetERC20Detail();

  useEffect(()=>{
    fetchTokenInfo(address)
  },[])

  return (
    <a href="#" onClick={(e)=>{e.preventDefault();onSelect({
      address: address,
      name: name
    })}}>
      <div className="flex flex-row justify-between p-2 my-2 border">
        <div className="flex flex-col w-1/2">
            <>
              <strong>{name}</strong>
              <small>{symbol}</small>
            </>
          
        </div>
        <div className="flex flex-row justify-end w-1/2">
          {isLoadingTokenInfo && (
            <span className="text-lg font-bold text-green-400 animate-pulse text-dark-500">    
                <p className="h-3 mb-1 leading-relaxed bg-gray-400 w-14 animate-pulse"></p>
            </span>
          )}
          {!isLoadingTokenInfo && (
            <strong>{tokenBalance}</strong>
          )}
        </div>
      </div>
    </a>
  )
}

export const SelectToken = ({show,onSelect=(e)=>{}}) => {
  const [isShowModal,setIsShowModal] = useState(show);
  const [tokenAddress,setTokenAddress] = useState("")
  const [defaultToken,setDefaultToken] = useState([
    "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    "0x2170Ed0880ac9A755fd29B2688956BD959F933F8" 
  ])
  const [{listToken,isLoadingListToken},fetchListToken,setListToken] = useGetListToken()
  const [listKey,setListKey] = useState(0);
  const [isSetDefaultListToken,setIsSetDefaultListToken] = useState(true);
  const [defaultListToken,setDefaultListToken] = useState([]);

  useEffect(()=>{
    fetchListToken(defaultToken)
  },[])

  useEffect(()=>{
    if(isSetDefaultListToken){
      setDefaultListToken(listToken)
    }    
  },[listToken])

  useEffect(()=>{
    if(defaultListToken.length > 0) setIsSetDefaultListToken(false)
  },[defaultListToken])

  useEffect(()=>{
    setIsShowModal(show);
  },[show])

  useEffect(()=>{
    if(!tokenAddress || tokenAddress.length == 0) setListToken(defaultListToken)
    else if(tokenAddress.length < 42) {
      const tmp = []
      defaultListToken.map((v,i,a)=>{
        if(v.name.toLowerCase().includes(tokenAddress.toLowerCase())) tmp.push(v)
      })
      setListToken(tmp)
    }
    else fetchListToken([tokenAddress])

    setListKey(listKey+1)
  },[tokenAddress])


  return (
    <>
      <Transition appear show={isShowModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={()=>setIsShowModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Select Token
                  </Dialog.Title>
                  <div className="mt-2">
                    <form>
                      <div className="w-full">
                        <div className="w-full mt-3">
                          <label
                            htmlFor="token-contract-address"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Contract Address
                          </label>
                          <div className="flex w-full mt-1 rounded-md shadow-sm">
                            <input
                              type="text"
                              name="token-contract-address"
                              id="token-contract-address"
                              className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              placeholder="Example: BSAFU Token"
                              onChange={(e)=>setTokenAddress(e.target.value)}
                            />
                          </div>
                        </div>
                        <div key={listKey}>
                        { listToken.map((v,i,a)=>{
                          return (
                            <RowToken address={v?.address} name={v?.name} symbol={v?.symbol} key={v?.address+"-"+i} onSelect={(e)=>onSelect(e)} />
                          )
                        })}
                        </div>
                      </div>
                    </form>
                    
                  </div>

                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export const Attachment = ({
  type,
  title,
  description,
  onChange=(e)=>{}
}) => {
  const [isLoading,setIsloading] = useState(false)
  const [selectedFile,setSelectedFile] = useState(null)

  useEffect(()=>{
    onChange(selectedFile)
  },[selectedFile])

  const onSelectedFile = (e)=>{
    const target = e.target.files[0];
    const formData = new FormData();
    formData.append('file',target,target.name)
    
    formData.append('type',type)
    formData.append('title', title);
    formData.append('description',description);
    
    axios.post("https://cdn.webooswap.com/v1/files",formData,{
      headers: {
        Accept: 'application/json',
        "Content-Type": 'multipart/form-data',
        Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxOCIsImp0aSI6IjExNDViMTkyNTQxYTI4ZmFhYTQxYTJlZjFiNmZhOWM3ZjVkNGMxMzY0MzViODZiZjdlNjhiOWRkNGMzMGM1NDI0N2Q5MWJkZWQ2MzYxMjYxIiwiaWF0IjoxNjYwNTQ0NDAyLjQ3MTM3MiwibmJmIjoxNjYwNTQ0NDAyLjQ3MTM3NiwiZXhwIjoxNDYyMDU0NDQwMi4zNjkzNSwic3ViIjoiNjUiLCJzY29wZXMiOltdfQ.o9B7XKFSb7Y4_DxbuqSjAuHnFKrHSoTvuWqt6-mnLLlbNNtTLj8FilDIY_u7Wy81kOp_ikL3soHDeUdOzmsJZQ379pqb7x4d14OEtP2hclN4pw0msUARyOo42mN6fZ84FGgdpdrfDUYPtE5nFezLSDO-Na8favB0g5ku2OnFb5YqR-OLl37iuxPzWtyVvvex-0KBaSCQdBmN9HNWnDvQOpv8LCwpDNUPwYmQXQkL0RO2VhEPToxVl2H4aeq-k-laTsfIR8HSWAXmjH4-TxjDX1dIl1qTLqBq3zkA3IZQJtDjrT22p2r1Tg_Jj9gmSz6zDKCf1aSxzPSflwFDKAa1RQNxj3BddQhGUsyoA4Kn1IpCRngoJKXkK1Sxe3xec1ktmDGiVHGPsOEvVGNV2whsLGPCn495D4AlGuFqbMnyV72ohzPBD9DA-33bAi42EtCfYmbVjubVscaUc8fvSSx-CNSVnWl5fLViyPPL3_9g-0u2YdAcD6UCSCe5oKtFdZZkC6EKUEXa-ClA_x_zxtTqJxlnMaA9AHIKtHDugf2SInzv8ucBjEZbEB7RUMYfc_6-r-b18PtlStjfsy4xBgx1RXb5jCvSvgrm2RrI5JTG-uewD6YratSQOSZe1PU8nhqBN2j43zqdK9YmH3dbMVwLnssqGZ1kkVUfoTD87ago7WU'
      }
    }).then(res=>{
      setIsloading(false)
      setSelectedFile(res.data.result)
    })
  }

  return (
    <div className="w-full">
      {isLoading && (
        <span className="text-lg font-bold text-green-400 animate-pulse text-dark-500">    
          <p className="w-full h-10 py-2 mb-3 leading-relaxed bg-gray-400 animate-pulse"></p>
      </span>
      )}
      {!isLoading  && !selectedFile && (
        <input type={"file"} className="flex-1 block w-full px-2 py-2 border border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" onChange={(e)=>onSelectedFile(e)} />
      )} 
      {!isLoading  && selectedFile && (
        <div className="flex items-center p-3 border rounded-md">
          <a href="#" className="mr-3 text-red-600" onClick={(e)=>setSelectedFile(null)}>
            <FaTimes />
          </a>
          <a href={selectedFile?.file} className="text-blue-400" target={"_blank"}>
            View Attachment
          </a> 
        </div>
      )} 
    </div>
  )
}
